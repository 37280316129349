<template>
  <div v-if="showButton" class="download-button">
    <v-btn flat color="primary" variant="tonal" @click="install">
      <v-icon class="mr-2">mdi-download</v-icon>
      Stáhnout aplikaci
    </v-btn>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

const showButton = ref(false);
const installPrompt = ref(null);

onMounted(() => {
  console.log("mounted");
  window.addEventListener("appinstalled", () => {
    disableInAppInstallPrompt();
  });

  window.addEventListener("beforeinstallprompt", (event) => {
    console.log("beforeinstallprompt fired");
    event.preventDefault();
    installPrompt.value = event;
    showButton.value = true;
  });
});

const install = async () => {
  if (installPrompt.value) {
    installPrompt.value.prompt();
    const result = await installPrompt.value.userChoice;
    console.log(`Install prompt was: ${result.outcome}`);

    if (result.outcome === "accepted") {
      console.log("User accepted the install prompt");
    } else {
      console.log("User dismissed the install prompt");
    }
    disableInAppInstallPrompt();
  }
};

const disableInAppInstallPrompt = () => {
  showButton.value = false;
  installPrompt.value = null;
};
</script>

<style lang="scss" scoped></style>
