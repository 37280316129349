<template>
  <v-dialog v-model="showDialog" max-width="500" persistent>
    <div class="bg-white pa-8">
      <div class="text-h5 mb-4 text-center">
        Zadejte kód pro vstup do kalkulátoru
      </div>

      <v-alert v-if="showError" type="error" class="mb-4">
        <div>Zadali jste špatný kód.</div>
      </v-alert>
      <form @submit.prevent="onSubmit">
        <v-text-field label="Kód" v-model="enterCode"></v-text-field>
        <v-btn flat color="primary" block size="x-large" type="submit"
          >Vstoupit</v-btn
        >
      </form>
    </div>
  </v-dialog>
  <v-app v-if="showCalculator">
    <v-main>
      <v-container>
        <router-view />
      </v-container>

      <v-snackbar v-model="snackWithButtons" bottom left timeout="-1">
        {{ snackWithBtnText }}
        <template v-slot:actions="{ attrs }">
          <v-btn text color="#00f500" v-bind="attrs" @click.stop="refreshApp">
            {{ snackBtnText }}
          </v-btn>
          <v-btn icon class="ml-4" @click="snackWithButtons = false">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>

    <div class="text-center mt-8">
      <DownloadButton />
    </div>
    <small> Version 0.0.9 </small>
  </v-app>
</template>

<script>
import DownloadButton from "@/components/DownloadButton.vue";

export default {
  name: "App",
  data() {
    return {
      showDialog: false,
      showCalculator: false,
      enterCode: "",
      refreshing: false,
      registration: null,
      snackBtnText: "Aktualizovat",
      snackWithBtnText: "Je dostupná nová verze kalkulátoru!",
      snackWithButtons: false,
      showError: false,
    };
  },
  components: {
    DownloadButton,
  },
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },

  mounted() {
    const codeEntered = localStorage.getItem("codeEntered");
    if (codeEntered) {
      this.showDialog = false;
      this.showCalculator = true;
    } else {
      this.showDialog = true;
    }
  },
  methods: {
    onSubmit() {
      if (this.enterCode === "xneq") {
        this.showDialog = false;
        this.showCalculator = true;
        this.showError = false;
        localStorage.setItem("codeEntered", true);
      } else {
        this.showError = true;
      }
    },
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      console.log("sw instance", e);
      this.registration = e.detail;
      this.snackBtnText = "Aktualizovat";
      this.snackWithBtnText = "Nová verze k dispouzici!";
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
};
</script>

<style lang="scss">
#app {
  .v-application {
    background-color: #f1efee;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    text-align: center;
  }

  h1 {
    color: #a50235;
  }
}
</style>
