<template>
  <div class="container">
    <v-select
      v-model="selectedCompetition"
      :items="competition"
      item-title="name"
      :item-value="returnItem"
      label="LRHR agonista"
    ></v-select>
    <!-- <v-text-field
      name="firmagonPatientCount"
      label="Počet pacientů na Firmagonu"
      type="number"
      min="0"
      v-model="firmagonPatientCount"
    >
    </v-text-field> -->
    <v-text-field
      name="firmagonPatientCount"
      label="Počet pacientů na LRHR agonistovi"
      type="number"
      min="0"
      v-model="competitionPatientCount"
    >
    </v-text-field>
    <div class="text-left bg-grey-lighten-4 pa-8 rounded-xl">
      <div>
        <div>Stávající roční náklady pacientů na LRHR agonistovi</div>
        <div class="mt-2">
          <span class="text-h5">{{ competitionYearCost }}</span
          ><span> Kč</span>
        </div>
      </div>

      <div class="mt-4">
        <div>Pokles Vašeho ročního rozpočtu po snížení úhrady</div>
        <div class="text-red mt-2">
          <span class="text-h5">{{ yearBudgetDecrease }}</span>
          <span> Kč</span>
        </div>
      </div>
      <div class="mt-4">
        <div>
          Kolik lze převézt pacientů na Firmagon, aby rozpočet zůstal stejný
        </div>
        <div class="mt-2">
          <span class="text-h5">{{ switchPatientCount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

const firmagonPatientCount = ref(0);
const competitionPatientCount = ref(0);
const selectedCompetition = ref(null);

const firmagon120mgMonthCost = 3516.85;
const firmagon80mgMonthCost = 3516.85;

const competition = [
  {
    name: "DIPHERELINE  22,5 MG",
    cost: 1271.03,
    newCost: 889.721,
    patientCount: 0,
  },
  {
    name: "DIPHERELINE 11,25 MG",
    cost: 1271.03,
    newCost: 889.721,
    patientCount: 0,
  },
  {
    name: "ELIGARD 45 MG",
    cost: 1271.03,
    newCost: 889.721,
    patientCount: 0,
  },
  {
    name: "ELIGARD 22,5 MG",
    cost: 1271.03,
    newCost: 889.721,
    patientCount: 0,
  },
  {
    name: "LEPTOPROL 5 MG",
    cost: 1271.03,
    newCost: 889.721,
    patientCount: 0,
  },
  {
    name: "RESELIGO 10,8 MG",
    cost: 1271.03,
    newCost: 889.72,
    patientCount: 0,
  },
  {
    name: "ZOLADEX DEPOT 10,8 MG",
    cost: 1271.03,
    newCost: 889.721,
    patientCount: 0,
  },
];

watch(
  () => competitionPatientCount.value,
  (value) => {
    if (value < 0) {
      competitionPatientCount.value = 0;
    }
  }
);

watch(
  () => firmagonPatientCount.value,
  (value) => {
    if (value < 0) {
      firmagonPatientCount.value = 0;
    }
  }
);

const firmagonYearCost = computed(() => {
  return (
    firmagonPatientCount.value *
    12 *
    firmagon80mgMonthCost
  ).toLocaleString("cs-CZ", {
    maximumFractionDigits: 2,
  });
});

const competitionYearCost = computed(() => {
  return (
    competitionPatientCount.value * 12 * selectedCompetition?.value?.cost || 0
  ).toLocaleString("cs-CZ", {
    maximumFractionDigits: 2,
  });
});

const yearBudgetDecrease = computed(() => {
  if (selectedCompetition.value === null) {
    return 0;
  }
  return (
    (selectedCompetition?.value?.cost - selectedCompetition?.value?.newCost) *
    12 *
    competitionPatientCount.value
  ).toLocaleString("cs-CZ", {
    maximumFractionDigits: 2,
  });
});

const switchPatientCount = computed(() => {
  if (selectedCompetition.value === null) {
    return 0;
  }
  return (
    ((selectedCompetition?.value?.cost - selectedCompetition?.value?.newCost) *
      12 *
      competitionPatientCount.value) /
    (firmagon80mgMonthCost * 12)
  ).toLocaleString("cs-CZ", {
    maximumFractionDigits: 2,
  });
});

const returnItem = (item) => {
  return item;
};

function getCapitalizeName(name) {
  if (typeof name !== "string" || name.length === 0) {
    return "";
  }

  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 31rem;
  margin: auto;
  border: 1px solid gainsboro;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
}
</style>
